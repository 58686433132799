<template>
    <div class="container pt-3">
        <span class="small" @click="goBack()" role="button"><i class="fa-solid fa-chevron-left me-2"></i>Tagasi</span>
        <div class="table-responsive">
            <table class="table mt-3 small">
                <thead>
                    <tr>
                        <th scope="col">Aeg</th>
                        <th scope="col">Konto</th>
                        <th scope="col">Signaal</th>
                        <th scope="col">Tüüp</th>
                        <th scope="col">Kirjeldus</th>
                        <th scope="col">Dispositioon</th>
                        <th scope="col">Selgitus</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="signal in signals" :key="signal.EventSummaryId">
                        <td>{{ signal.received }}</td>
                        <td>{{ signal.account }}</td>
                        <td>{{ signal.zone }}</td>
                        <td>{{ signal.eventType }}</td>
                        <td>{{ signal.description }}</td>
                        <td>{{ signal.disposition }}</td>
                        <td>{{ signal.comment }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from '@/services/axios';
import { useRoute } from 'vue-router';

export default {
    data() {
        return {
            signals: []
        };
    },
    setup() {
        const route = useRoute();
        const projectId = route.params.projectId;
        const simsAccountId = route.params.simsAccountId;

        return { projectId, simsAccountId };
    },
    methods: {
        goBack() {
            if (this.$route.meta.internalNavigation && window.history.length > 2) {
                this.$router.go(-1);
            } else {
                this.$router.push('/');
            }
        },
        async fetchSignals() {
            try {
                const response = await axios.get(`/projects/${this.projectId}/sims-accounts/${this.simsAccountId}/signals`);
                this.signals = response.data.data;
            } catch (error) {
                console.error('Error fetching signals:', error);
                this.$root.$refs.globalAlert.showAlert('Signaal puudub', 'danger');
            }
        },
    },
    mounted() {
        this.fetchSignals();
    },
    created() {
        this.interval = setInterval(this.fetchSignals, 10000);
    },
    beforeUnmount() {
        clearInterval(this.interval); // Clear the interval when component is destroyed
    }
};
</script>
