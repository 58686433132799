<template>
    <div class="container">
        <span @click="$emit('close')" role="button"><small><i
                    class="fa-solid fa-chevron-left me-2"></i>Tagasi</small></span>
        <h2 class="my-3">Kliendile</h2>

        <div class="mb-3 text-multiline text-break">
            <label class="form-label"><small>Tehtud töö</small></label>
            <div class="bg-white p-3 small">{{ this.notes2 }}</div>
        </div>

        <div class="mb-3">
            <label class="form-label"><small>Materjal</small></label>
            <ul v-if="this.materials.length > 0" class="list-group list-group-flush">
                <li v-for="resource in this.materials" :key="resource.id"
                    class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="me-auto">
                        <div class="small">{{ resource.resource_name }}, {{ resource.amount
                            }}tk
                        </div>
                        <div class="text-muted text-multiline"><small>{{ resource.description
                                }}</small>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="mb-3">
            <label class="form-label"><small>Aeg</small></label>
            <ul v-if="this.hours.length > 0" class="list-group list-group-flush">
                <li v-for="resource in this.hours" :key="resource.id"
                    class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="me-auto">
                        <div class="small">{{ resource.resource_name }}, {{ resource.amount
                            }}tk
                        </div>
                        <div class="text-muted text-multiline"><small>{{ resource.description
                                }}</small>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="mb-3 form-check">
            <input type="checkbox" v-model="isNotSigned" class="form-check-input" id="signedCheckbox">
            <label class="form-check-label small" for="signedCheckbox">Kliendi allkirja ei olnud võimalik saada</label>
        </div>
        <div class="mb-3">
            <label class="form-label"><small>Kliendi allkiri</small></label>
            <vue-signature-pad class="bg-white" ref="signaturePadCustomer" height="200px"></vue-signature-pad>
        </div>
        <button class="btn btn-sm border-1 border-primary text-primary" @click="clearSignatureCustomer">Puhasta</button>
        <div class="my-3">
            <label class="form-label"><small>Tehniku allkiri</small></label>
            <vue-signature-pad class="bg-white" ref="signaturePadUser" height="200px"></vue-signature-pad>
        </div>
        <button class="btn btn-sm border-1 border-primary text-primary" @click="clearSignatureUser">Puhasta</button>
        <div class="row mt-3">
            <div class="col">
                <button @click="$emit('close')" class="btn border-1 border-primary text-primary w-100">Loobu</button>
            </div>
            <div class="col">
                <button @click="submitSignature" class="btn btn-primary w-100">Allkirjasta</button>
            </div>
        </div>
    </div>
</template>

<script>

import axios from '@/services/axios';
import { VueSignaturePad } from 'vue-signature-pad';

export default {
    components: {
        VueSignaturePad,
    },
    props: {
        taskId: {
            type: Number,
            required: true
        },
        notes2: {
            type: String,
            required: true
        },
        hours: {
            type: Array,
            required: true
        },
        materials: {
            type: Array,
            required: true
        },

    },
    data() {
        return {
            isNotSigned: false
        };
    },
    methods: {
        async submitSignature() {
            try {
                const customerSignatureData = this.$refs.signaturePadCustomer.saveSignature();
                const userSignatureData = this.$refs.signaturePadUser.saveSignature();
                const payload = {
                    notes2: this.notes2,
                    signatureCustomer: customerSignatureData,
                    signatureUser: userSignatureData,
                    isSigned: !this.isNotSigned
                };

                const response = await axios.post(`/tasks/${this.taskId}/sign`, payload);

                if (response.status === 200) {
                    this.$emit('signature-added');
                    this.$emit('close');
                }
            } catch (error) {
                console.error('Error submitting signature:', error);
            }
        },
        clearSignatureCustomer() {
            this.$refs.signaturePadCustomer.undoSignature();
        },
        clearSignatureUser() {
            this.$refs.signaturePadUser.undoSignature();
        }
    }
}
</script>

<style scoped></style>
